/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";


/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.invalid-tooltip {
  margin-top: -8px;
}


img {
  border: 0;
  outline: none;
  max-width: 100%;
}
